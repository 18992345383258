/* eslint-disable */
import {lista, nao_autorizado, pegaDados, processar} from '../../services/GenericAPI'
import {Filtro} from "../../models/filtro/filtro";
import {PrevisaoCaixa} from '../../models/previsao_caixa'
import {PrevisaoCaixaSCH, getPrevisaoCaixaSCH} from "../../search/PrevisaoCaixaSCH";

const url = '/previsao_caixa'

const state = {
  all: [],
  allList: [],
  previsao_caixa: new PrevisaoCaixa(),
  filtro: new Filtro()
}

const getters = {
  listaAllPrevisaoCaixas: (state) => {
    return state.allList
  },

  listaPrevisaoCaixas: (state) => {
    return state.all
  },
  pegaPrevisaoCaixa: (state) => {
    return state.previsao_caixa
  },
  pegaFiltro: (state) => {
    return state.filtro
  }
}

const actions = {
  searchItem({commit}, search) {
    state.filtro.filters.search = [];
    state.filtro.filters.addSearch(['valor','eventos_caixa.descricao'], '%' + search + '%', 'ilike')
  },
  async getItem({commit}, {id, onSucesso,onErro}) {
    try {
      var filtro = getPrevisaoCaixaSCH('previsao_caixa_id', id, '=')
      const list = (await pegaDados(url+'/get', filtro)).data
      if (list.data[0] !== undefined) {
        commit('SET_PREVISAO_CAIXA', list.data[0])
        onSucesso(list.data[0])
      } else {
        onErro()
      }
    } catch (error) {
      onErro()
      console.log(error)
    }
  },

  setFiltro({commit}, perPage) {
    commit('SET_FILTRO', PrevisaoCaixaSCH(perPage))
  },

  async setAllListPrevisaoCaixas({commit},{onSucesso, onErro}) {
    try {
      const list = (await lista(url + '/all')).data
      commit('SET_ALL_LIST', list)
      onSucesso()
    } catch (error) {
      console.log(error)
      onErro()
    }
},
  async setAllPrevisaoCaixas({commit},{onSucesso, onErro}) {
    try {
      const list = (await pegaDados(url + '/get', state.filtro)).data
      commit('SET_PREVISAO_CAIXAS', list)
      onSucesso()
    } catch (error) {
      console.log(error)
      onErro()
      nao_autorizado(error)
    }
  },
  async processarPrevisaoCaixa({commit}, {acao,onSucesso,onErro}) {
    try {
      let res = await processar(url + (acao === 'gravar' ? '/commit' : '/delete'), state.previsao_caixa, acao)

      this.dispatch('geral/setMsgSuccess', res.data.message)
      onSucesso()
    } catch (error) {
      onErro()
      console.log('error: ',error)
      this.dispatch('geral/setObjMsgError', error.response.data)
    }
  },
  async processarReplicasPrevisaoCaixa({commit}, {lista,onSucesso,onErro}) {
    try {
      let res = await processar(url + '/commit_replicas' ,lista, 'gravar')

      this.dispatch('geral/setMsgSuccess', res.data.message)
      onSucesso()
    } catch (error) {
      onErro()
      console.log('error: ',error)
      this.dispatch('geral/setObjMsgError', error.response.data)
    }
  },
  setPrevisaoCaixa({commit}, previsao_caixa) {
    commit('SET_PREVISAO_CAIXA', previsao_caixa)
  },
}

const mutations = {

  SET_ALL_LIST(state, filtro) {
    state.allList = filtro
  },
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  },
  SET_PREVISAO_CAIXAS(state, previsao_caixas) {
    state.all = previsao_caixas
  },
  SET_PREVISAO_CAIXA(state, previsao_caixa) {
    state.previsao_caixa = previsao_caixa
  },

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
