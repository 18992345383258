/* eslint-disable */
import {Filtro} from "../models/filtro/filtro";

export const  getPrevisaoCaixaSCH = (campo, valor, condicao)=>{
   let filtro = new Filtro();
   filtro.perPage = 1;
   filtro.filters.addFilter(campo,valor,condicao);
   filtro.filters.addFilter('empresa_id',sessionStorage.getItem('empresa_id'),'=')
   filtro.fields = ['*'];
   return filtro
};
export const PrevisaoCaixaSCH = (perPage) => {
   var filtro = new Filtro()
   filtro.perPage = perPage
   filtro.fields = ['data','valor','previsao_caixa.evento_caixa_id','eventos_caixa.descricao']
   filtro.join.addLeft('eventos_caixa','eventos_caixa.evento_caixa_id','previsao_caixa.evento_caixa_id')
   filtro.filters.addFilter('previsao_caixa.empresa_id',sessionStorage.getItem('empresa_id'),'=')
   // filtro.filters.addFilterRaw(" to_char(data, 'MM') = '01'" )
   filtro.addOrder('data','desc')

   return filtro
}
